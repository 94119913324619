@import '~@alkem/react-styles/src/vars';

.InputWithLabel {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.InputWithLabel__label {
  flex-basis: 35%;
  display: flex;
  justify-content: flex-end;
  margin-right: $margin-base-horizontal;
}

.InputWithLabel__input {
  flex-basis: 65%;
}
