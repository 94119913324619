@import '~@alkem/react-styles/src/vars';

.GDSNImportMappingsItemTest__sectionTitle {
  margin-top: 1.25em;
  border-bottom: 1px solid $gray-light;
  font-weight: 500;
}

.GDSNImportMappingsItemTest .InputWithLabel {
  margin-top: 0.25em;
}

.GDSNImportMappingsItemTest .InputWithLabel__input {
  display: flex;
  flex-direction: column;
}

.GDSNImportMappingsItemTest__codeEditorsContainer {
  margin-top: 0.25em;
  display: flex;
}

.GDSNImportMappingsItemTest__buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  margin-top: 0.25em;
}
