@import '~@alkem/react-styles/src/vars';

.TransactionDiffModal {
  width: 80vw;
  height: 80vh;

  .TransactionDiffModal__body.Modal__body.Modal__body--full {
    height: calc(100% - 60px);
  }
}

.TransactionDiffModal__Content {
  width: 100%;
  font-size: 1rem;
}

.TransactionDiffModal__rawDiff {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.TransactionDiffModal__rawDiffPayload {
  margin-top: 1rem;
  width: 100%;
  height: 100%;
}

.codeMarker {
  background: rgba($brand-danger, 0.3);
  position: absolute;
  z-index: 20;
}
