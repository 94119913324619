@import '~@alkem/react-styles/src/vars';

.BulkEditRulesModal {
  h3 {
    font-weight: 500;
    font-size: $font-size-h3;
    margin-bottom: $half-margin-base-horizontal;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;

    li {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      label.ReactUiCheckbox {
        margin-right: $half-margin-base-horizontal;
      }
    }
  }

  .Text__warning {
    color: $brand-highlight;
  }

  .ValidationSelectRuleset_SelectInput {
    margin-bottom: $margin-base-horizontal;
  }
}
