@import '~@alkem/react-styles/src/vars';

.GDSNExportMappingsItemConstants__sectionTitle {
  margin-top: 1.25em;
  border-bottom: 1px solid $gray-light;
  font-weight: 500;
}

.GDSNExportMappingsItemConstants {
  border-width: 1px;
}

.GDSNExportMappingsItemConstants .InputWithLabel {
  margin-top: 0.25em;
}
