@import '~@alkem/react-styles/src/vars';

$filter-list-line-height: 28px;

.FilterList {
  padding: 0;
  margin: 0;
  margin-left: -$margin-base;
  margin-right: -$margin-base;
  list-style: none;
}

.FilterList__radio {
  display: none;
  border-radius: 0;
}

.FilterList__radio:checked + label {
  position: relative;
  padding: 0 0 0 29px;
  z-index: 1;
  background: repeating-linear-gradient(
    to bottom right,
    rgba(243, 236, 225, 0.6),
    rgba(243, 236, 225, 0.6) 33%,
    rgba(227, 221, 213, 0.6) 33%,
    rgba(227, 221, 213, 0.6) 66%,
    rgba(243, 236, 225, 0.6) 66%,
    rgba(243, 236, 225, 0.6)
  );
  background-size: 3px 3px;
  border: #e6d5ba 1px solid;
}

label.FilterList__statusLabel {
  cursor: pointer;
  min-height: 30px;
  line-height: $filter-list-line-height;
  font-weight: 300;
  margin: 0;
  padding: 0 0 0 29px;
  display: flex;
  align-items: center;
}

.FilterList__statusLabel--noicon {
  padding-left: 29%;
}

.FilterList__count {
  float: right;
  padding-right: $padding-base-horizontal;
}

.FilterList__icon {
  height: $filter-list-line-height;
  padding-right: $padding-base-horizontal;
}
