@import '~@alkem/react-styles/src/vars';

$callDetailColorSucceeded: $brand-success;
$callDetailColorInterrupted: $alk-red;
$callColorNoneSelected: $brand-inprogress;

.RuleApplierCallDetail--completed {
  border-left: 2px solid $callDetailColorSucceeded;
}

.RuleApplierCallDetail--interrupted {
  border-left: 2px solid $callDetailColorInterrupted;
}

.RuleApplierCallDetail--none {
  border-left: 2px solid $callColorNoneSelected;
}

.RuleApplierCallDetail__Code {
  color: black;
}

.RuleApplierCallDetail__Duration {
  font-style: italic;
  font-weight: lighter;
}

.RuleApplierCallDetail {
  ul {
    list-style: none;
  }
}

.RuleApplierCallDetail__Parameters--empty {
  display: inline;
}
