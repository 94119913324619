@import '~@alkem/react-styles/src/vars';

.IndexerQueueDetail {
  background-color: $gray-really-light;
  border: 1px solid $gray-border;
  padding: 0.5em 1em;
}

.IndexerQueueDetailRow,
.IndexerQueueDetailHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.2em;
}

.IndexerQueueDetailRow {
  background-color: white;
  min-height: 3em;
}

.IndexerQueueDetailRow__column {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.IndexerQueueDetailRow__source {
  flex-basis: 40%;
}

.IndexerQueueDetailRow__priority {
  flex-basis: 20%;
}

.IndexerQueueDetailRow__data {
  flex-basis: 20%;
  justify-content: right;
}
