@import '~@alkem/react-styles/src/colors';

.ErrorFallback {
  display: flex;
  justify-content: center;
  padding: 2em 1em;
}

.ErrorFallback,
.ErrorFallback__content,
.ErrorFallback__stack {
  max-width: 100%;
}

.ErrorFallback__title {
  font-weight: 500;
  color: $brand-danger;
}

.ErrorFallback__stack {
  white-space: pre-wrap;
}
