@use 'sass:math';

@import '~@alkem/react-styles/src/vars';

.RetailerProductPictureComparator {
  padding: $padding-base-vertical $padding-base-horizontal;
}

.RetailerProductPictureComparator__header {
  display: flex;
  justify-content: space-between;
  .InputText {
    flex-grow: 1;
    margin-right: $margin-base;
  }
  .Select {
    margin-right: $margin-base;
  }
}

.RetailerProductPictureComparator__versions {
  margin-top: $margin-base;
  padding: $padding-base-vertical $padding-base-horizontal;
}

.RetailerProductPictureComparator__checkedGTINs {
  margin-top: $half-margin-base;
  margin-bottom: $half-margin-base;
}

.RetailerProductPictureComparator__version {
  display: flex;
  justify-content: space-between;
  margin-top: math.div($half-margin-base, 2);
  border: 1px solid $gray-light;
  padding: $padding-base-vertical;
  background-color: $brand-white;
}

.RetailerProductPictureComparator__checkbox {
  flex-basis: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RetailerProductPictureComparator__versionItem {
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
}

.RetailerProductPictureComparator__versionItemInfo {
  display: flex;
  flex-direction: row;
  margin-bottom: $half-margin-base;
}

.RetailerProductPictureComparator__versionItemTitle {
  font-weight: 400;
  margin-right: $half-margin-base-horizontal;
}
