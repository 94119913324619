@import '~@alkem/react-styles/src/vars';

.GDSNExportMappingsListElementItemType .InputWithLabel {
  margin-top: 0.25em;
}

.GDSNExportMappingsListElementItemType
  .InputWithLabel
  .InputWithLabel__label
  > label {
  margin-bottom: 0px;
}

.GDSNExportMappingsListElementItemType__typeProperty,
.GDSNExportMappingsListElementItemType__fieldSelector__simpleSelect,
.GDSNExportMappingsListElementItemType__customParseSelector__simpleSelect {
  display: flex;
  align-items: center;
}

.GDSNExportMappingsListElementItemType__reset-button {
  color: $brand-primary;
  margin-left: 10px;
}

.GDSNExportMappingsListElementItemType__fieldSelector__search {
  margin-bottom: 0.25em;
}

.GDSNExportMappingsListElementItemType__fieldSelector__simpleSelect
  > .SimpleSelect,
.GDSNExportMappingsListElementItemType__customParseSelector__simpleSelect
  > .SimpleSelect {
  flex: 1;
  .SimpleSelect_value {
    overflow-wrap: anywhere;
  }
}
