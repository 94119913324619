@import '~@alkem/react-styles/src/vars';

$row-height-small: 32px;
$row-height: 64px;

.ImportFileListRowWrapper {
  margin: $padding-small-vertical 0;

  .ImportFileListRow {
    cursor: auto;

    background-color: white;
    border: 1px solid $gray-border;
    min-height: $row-height;
    position: relative;
    align-items: center;

    .ImportFileSupportStatus {
      min-width: 150px;
      align-items: right;

      .SimpleSelect {
        align-items: left;
        justify-content: flex-end;

        .SimpleSelect__content {
          border: 0;
        }

        .SimpleSelect_value {
          display: flex;
          justify-content: flex-end;
          padding-right: 2px;
          padding-left: 5px;

          .mdi {
            margin-top: 2px;
            margin-right: 5px;
          }

          .mdi-clock {
            color: $brand-highlight;
          }

          .mdi-calendar {
            color: $brand-warning;
          }

          .mdi-check {
            color: $brand-success;
          }
        }

        .ChoiceTree {
          display: block;
          padding-left: -5px;
          justify-content: flex-end;

          .ChoiceTreeItem__chevron {
            display: none;
          }
        }
      }
    }

    .ImportStatus {
      display: flex;

      .SimpleSelect__content {
        border: 0;
        text-align: right;
      }

      .SimpleSelect_value {
        .SimpleSelect_status {
          &--SUCCESS {
            color: $brand-success;
          }

          &--CONTAINS_ERRORS {
            color: $alk-orange;
          }

          &--TIMEOUT {
            color: $alk-purple;
          }

          &--IN_PROGRESS {
            color: $brand-highlight;
          }

          &--EMPTY {
            color: $gray-medium;
          }

          &--DONE {
            color: $gray-medium;
          }

          &--ERROR {
            color: $brand-danger;
          }
        }
      }

      .ChoiceTree {
        text-align: left;
      }
    }

    .col-xs-4 {
      padding-left: 25px;
    }

    &:hover {
      background-color: $alk-background-hover;
    }

    &.active {
      background-color: $brand-primary-active;
      color: white;

      .ImportFileListRow__brand,
      .ImportFileListRow__classification {
        background-color: transparent;
        border: 1px solid white;
      }

      input[type='checkbox'].checkbox + label:before,
      input[type='checkbox'].checkbox:checked + label:after {
        border-color: white;
      }
    }

    .StatusBar {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
    }

    .Organizations_names p {
      margin-bottom: 0;
    }
  }

  .ImportFileListRow__tag {
    width: 100%;
    font-size: $font-size-small;
    color: #fff;
    border-radius: $border-radius-very-large;
    text-align: center;
    padding: $padding-xs-vertical $padding-xs-horizontal;
    margin: 1px 0;
  }

  .ImportFileListRow__tag_product {
    background-color: $brand-secondary;
    border: 1px solid $brand-secondary;
  }

  .ImportFileListRow__tag_replay {
    background-color: green;
    border: 1px solid green;
    cursor: pointer;
  }

  .ImportFileListRow__tag_range {
    background-color: #ff7449;
    border: 1px solid #ff7449;
  }

  .ImportFileListRow__tag_rfp {
    background-color: #c43a7d;
    border: 1px solid #c43a7d;
  }

  .ImportFileListRow__count {
    font-weight: bolder;
    text-align: center;
  }

  .ImportFileListRow__actions {
    text-align: right;

    .hidden {
      visibility: hidden;
    }
  }

  .ImportFileListRow__time {
    .mdi-alert {
      color: $brand-warning;
    }

    .mdi {
      margin-right: 5px;
    }
  }

  .ImportFileListRow__Steps {
    text-align: center;
  }

  .ImportFileListRow__Step {
    float: left;

    &--SUCCESS {
      color: $brand-success;
    }

    &--INPROGRESS {
      color: $brand-highlight;
    }

    &--ERROR {
      color: $brand-warning;
      cursor: pointer;
    }

    &--Icon {
      font-size: 18px;
    }
  }
}

.ImportDetailsRow_HierarchyTree {
  display: inline-block;
  width: 13px;
  height: 32px;

  .line {
    height: 50%;
    display: flex;
    flex-direction: row;
    .block {
      width: 50%;
      height: 100%;

      &.bottomRight.start {
        border-left: 1px grey solid;
      }

      &.topRight.start {
        border-bottom: 1px grey solid;
      }

      &.bottomRight.middle {
        border-left: 1px grey solid;
      }

      &.topRight.middle {
        border-left: 1px grey solid;
        border-bottom: 1px grey solid;
      }

      &.topRight.end {
        border-left: 1px grey solid;
        border-bottom: 1px grey solid;
      }
    }
  }
}

.ImportDetailsRow_FirstCell {
  display: flex;

  span {
    align-self: center;
  }
}

.ImportDetailsIcon {
  font-size: 24px;
  cursor: pointer;
}

.ImportDetailsIcon__Tooltip--Header {
  text-align: center;
}

.ImportDetailsRowWrapper {
  margin-left: $padding-large-horizontal * 2;
  margin-right: 0px;
  border: 0px;

  .ImportDetailsRow__Lines {
    border-bottom: 1px solid $gray-border;
    margin-right: -7.5px;
  }

  .ImportDetailsRow__actions {
    text-align: right;

    .hidden {
      visibility: hidden;
    }
  }

  .ImportDetailsRow {
    cursor: auto;
    margin-right: 0px;

    background-color: white;
    height: $row-height-small;
    align-items: center;
    border-right: 1px solid $gray-border;
    border-left: 1px solid $gray-border;

    &.ImportDetailsRow__Header {
      margin-right: -7.5px;
      border-bottom: 1px solid $gray-border;
      border-right: 1px solid $gray-border;
      border-left: 1px solid $gray-border;
    }

    &.ImportDetailsRow__Search {
      height: $row-height-small * 1.5;
      margin-right: -7.5px;
    }

    &:hover {
      background-color: $alk-background-hover;
    }

    .StatusBar {
      position: absolute;
      height: $row-height-small;
    }

    .ImportDetailsRow__errorMessage {
      display: flex;
      cursor: pointer;

      pre {
        margin: auto;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ImportDetailsRow_Navigation {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .ImportDetailsRow_NavigationSeparator {
        width: 15px;
      }
    }

    .ImportDetailsRow_TypePackagingIcon {
      height: 18px;
      filter: invert(100);
      margin: 0px 4px;
      align-self: center;
    }
  }
}

.ExcelIcon {
  height: 28px;
  margin-right: 7px;
}

.ImportDetailsRow__ExcelErrorReport {
  padding: 5px 16px;
}

.ImportDetailsRow__ErrorsOnly {
  height: 100%;
  .ReactUiCheckbox {
    margin: 15.5px;
  }
}

.ImportFileListRow__StepName--validate {
  cursor: pointer;
}
