@import '~@alkem/react-styles/src/vars';

.FieldDisplayInfo__specific {
  display: flex;
  margin-top: 0.5em;
  .Button {
    flex-grow: 1;
  }
}

.FieldDisplayInfo__specificItem {
  margin-top: $half-margin-base;
}

.FieldDisplayInfo__specificKindLabel,
.FieldDisplayInfo__specificPackagingTypeLabel {
  display: flex;
  i {
    margin-left: $half-margin-base-horizontal;
    font-size: x-large;
    color: $gray-light;
    &:hover {
      cursor: pointer;
      color: $gray-dark;
    }
  }
}

.FieldDisplayInfo__addKind {
  margin-top: $half-margin-base;
  margin-bottom: $margin-base;
}

.FieldDisplayInfo__translatable {
  display: flex;
  align-items: center;
  :first-child {
    flex-grow: 1;
  }
}
