@import '~@alkem/react-styles/src/vars';

.OrganizationPermissions__ValueOrDefault {
  display: flex;
  margin: 2px;
}

.OrganizationPermissions {
  table {
    width: 100%;

    td:first-child {
      white-space: nowrap;
      padding-right: 10px;
      max-width: 30%;
      width: 150px;
    }

    td:last-child {
      padding: 5px 0;
    }
  }

  &__section {
    margin-top: $margin-base;
  }

  &__warning {
    color: $brand-danger;
  }
}
