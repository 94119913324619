@import '~@alkem/react-styles/src/vars';

.CollapsibleReferentialSection {
  margin-bottom: $half-margin-base;

  .Collapsible__header {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }

  .Collapsible__content {
    padding: 20px;
  }
}
