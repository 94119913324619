@import '~@alkem/react-styles/src/vars';

.DisplayGroupPicker__link {
  display: flex;
  flex-direction: row;
  > * + * {
    margin-left: $half-margin-base-horizontal;
  }
}

.DisplayGroupPicker__linkLabel {
  border: 1px solid $gray-light;
  padding: $padding-base-vertical $padding-base-horizontal;
}
