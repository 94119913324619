@use 'sass:math';

@import '~@alkem/react-styles/src/vars';

.OrganizationUsesFieldsView__referentials {
  margin-top: $margin-base;
  background-color: $brand-white;
  border: 1px solid $gray-light;
  padding: $padding-base-vertical $padding-base-horizontal;
}

.OrganizationUsesFieldsView__referentials__tree .TwinPanel__item {
  overflow: inherit;
}

.OrganizationUsesFieldsView__referentials__tree__save {
  padding-top: math.div($margin-base, 2);
  padding-bottom: math.div($margin-base, 2);
}

.OrganizationUsesFieldsView__referentials__management {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.OrganizationUsesFieldsView__referentials__import {
  display: flex;
  flex-direction: column;
  margin-top: 1em;

  > div {
    padding: 1em;
  }
}

.OrganizationUsesFieldsView__referentials__import__parent {
  display: flex;
  flex-direction: column;
}

.OrganizationUsesFieldsView__referentials__import__url__row {
  display: flex;
}

.OrganizationUsesFieldsView__referentials__import__url__input {
  width: 60%;
}

.OrganizationUsesFieldsView__referentials__import__url__action {
  padding: 1em;
}

.OrganizationUsesFieldsView__imported__referentials {
  width: 100%;
}

.OrganizationUsesFieldsView__imported__referentials__row {
  width: 30%;
}

.OrganizationUsesFieldsView__referential__update__row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  > div {
    width: 25%;
    padding: 1em;
  }
}

.OrganizationUsesFieldsView__referential__data {
  &--invalid {
    .form-control {
      border: 1px solid $brand-danger;
      background: $brand-danger-hover;
    }
    input,
    textarea {
      border: none;
    }
  }
}
