@import '~@alkem/react-styles/src/vars';

.OrganizationPageBlock__gln {
  padding-bottom: 0.3rem;
}

.OrganizationPageBlock__gln_del {
  float: right;
  cursor: pointer;
}
