@import '~@alkem/react-styles/src/vars';

.ReportingTable {
  padding: 0px;
  overflow-x: scroll;

  & > table {
    background-color: transparent;
    border: 0;
    border-collapse: separate;
    border-spacing: 0;
    display: table;
    margin: 0;
    min-width: 100%;
    padding-right: 0.5em;

    & > thead {
      display: table-row-group;

      & tr {
        display: table-row;

        & > th {
          vertical-align: bottom;
          text-align: center;
          opacity: 0.5;

          & > span {
            display: inline-block;
            font-weight: 500;
            padding: 0 0.5rem 0.5rem;
          }
        }
      }
    }

    & > tbody {
      display: table-row-group;
      border-top: 1px solid $gray-lighter;

      & > tr {
        display: table-row;
        background-color: $brand-white;

        &:hover {
          background-color: $alk-background-hover !important;
        }

        &:nth-child(even) {
          background: #f4f9fb;
        }

        &:first-child > td {
          background-color: $gray-really-light;
          border-top: 2px solid $brand-success;
        }

        &.ListTableRow--selected {
          background-color: $alk-background-hover;
        }

        & > td {
          display: table-cell;
          vertical-align: middle;
          white-space: nowrap;
          padding: 0.75rem 0.5rem;
          width: 1px;
          border-bottom: 1px solid $gray-lighterer;
          font-weight: 300;
          text-align: center;

          &:first-child {
            padding-left: 1rem;
            text-align: left;
            font-weight: 500;
          }

          & > div {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.3em;
            max-height: 100%;
            max-width: 100%;
            min-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
          }
        }
      }
    }
  }
}
