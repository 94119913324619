@import '~@alkem/react-styles/src/vars';

.TransactionFilters {
  margin-bottom: 200px;
}

.TransactionFilters .ReactUiFilter__filtersBlock .ReactUiFilter__filterTitle {
  margin-top: $padding-base-horizontal;
}

.TransactionFilters__statusBar {
  position: absolute;
  height: 15px;
  width: 4px;
  left: 20px;

  &--NOT_VALIDATED {
    background-color: $brand-inprogress;
    opacity: 0.3;
  }

  &--VALIDATED {
    background-color: $brand-success;
  }

  &--NOT_ACTIVE {
    background-color: $brand-inprogress;
    opacity: 0.3;
  }

  &--ACTIVE {
    background-color: $brand-success;
  }

  &--IN_PROGRESS {
    background-color: $brand-highlight;
  }

  &--SUCCESS {
    background-color: $brand-success;
  }

  &--CONTAINS_ERRORS {
    background: $alk-orange-darker;
  }

  &--FAILURE {
    background-color: $brand-danger;
  }

  &--PENDING {
    background-color: $brand-secondary;
  }

  &--REFUSED {
    background: rgba($brand-danger, 0.7);
  }

  &--ACCEPTED {
    background: rgba(72, 176, 113, 0.3);
  }

  &--DISMISSED {
    background: $brand-danger;
  }

  &--TIMEOUT {
    background-color: $alk-purple;
  }
}

.TransactionFilters__helped {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .TransactionFilters__helped__subtext {
    font-size: 0.8rem;
    margin-left: auto;
    margin-right: 5px;
  }
}

.TransactionFilters__presetsSelect {
  margin: $padding-base-vertical $padding-base-horizontal;
}
