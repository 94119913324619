@import '~@alkem/react-styles/src/colors';

.Tabs__titles {
  padding: 1em 0;
  .nav-item:hover {
    background: $gray-lighterer;
  }
  .nav-item {
    cursor: pointer;
    &.active {
      cursor: default;
      background: $brand-primary;
      border-radius: 2px;
      .nav-link {
        color: white;
      }
    }
  }
}

.Tabs_TabBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
