@import '~@alkem/react-styles/src/vars';

.FilterTree__list,
.FilterTree__links {
  @include ul();
}

.FilterTree__links {
  padding-top: 0.4rem;
}

.FilterTree__seeMore,
.FilterTree__seeLess {
  width: 100%;
}

.TreeItem {
  position: relative;
  line-height: 1.3;

  label {
    width: 100%;
  }

  .Checkbox {
    width: 100%;
  }
}

.TreeItem__chevron {
  position: absolute;
  left: -20px;
  font-size: $font-size-h3;
  line-height: $font-size-h3;
  display: block;
}

.TreeItem__children {
  padding-left: 15px;
}

.ItemTree__icon:before {
  display: inline-block;
  border-radius: 50%;
  font-size: 14px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  margin: 0 4px 0 0;
}

.ItemTree__icon--green:before {
  background: $brand-success;
}

.ItemTree__icon--red:before {
  background: $brand-warning;
}

.ItemTree__icon--brown:before {
  background: $brand-inprogress;
}
