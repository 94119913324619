@import '~@alkem/react-styles/src/vars';

.GDSNImportMappingItemOptionalPropertyDefaultValue__message {
  font-weight: 500;
  color: $brand-warning;
}

.GDSNImportMappingsItemOptionalPropertyDefaultValue__listContainer {
  display: flex;
  align-items: center;
}

.GDSNImportMappingItem__reset-button {
  color: $brand-primary;
  margin-left: 10px;
}

.SimpleSelect {
  flex: 1;
}
