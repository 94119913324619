@import '~@alkem/react-styles/src/vars';

.AddUserModal {
  width: 765px;
  height: 550px;
}

.AddUserModal__passwordError {
  &.__react_component_tooltip {
    background-color: $brand-danger;
  }
}

.AddUserModal__userTipster {
  margin-top: 16px;
}

.AddUserModal__label {
  flex-basis: 10%;
}

.AddUserModal__input {
  flex-basis: 90%;
  margin-right: 16px;
}

.AddUserModal__input--error {
  border-color: $brand-danger;
  background-color: rgba($brand-danger, 0.1);
}

.AddUserModal__tooltip--error {
  background-color: $brand-danger !important;
}
