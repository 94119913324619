@import '@alkem/react-styles/src/vars';

.OrganizationListMultiple__Row {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;

  &:hover {
    background-color: $alk-background-hover;
  }
}

.OrganizationListMultiple__Columns {
  width: 60px;
  text-align: center;
  display: inline-block;

  &Label {
    font-size: small;
  }

  .ReactUiCheckbox {
    margin: 0;
  }
}

.OrganizationListMultiple__labelAndSubLabel {
  display: inline-flex;
  flex-direction: column;

  span + span {
    font-size: 0.7em;
  }
}
