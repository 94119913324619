@import '~@alkem/react-styles/src/vars';

$row-height-small: 32px;
$row-height: 64px;

.GDSNCIListRowWrapper {
  margin: $padding-small-vertical $padding-base-horizontal !important;
  width: 100%;
  font-size: $font-size-small;

  .GDSNCIListRow {
    cursor: auto;
    width: 100%;

    background-color: white;
    border: 1px solid $gray-border;
    height: $row-height;
    align-items: center;

    .col-xs-2 {
      padding-left: 5px;
    }

    &:hover {
      background-color: $alk-background-hover;
    }

    &.active {
      background-color: $brand-primary-active;
      color: white;
    }
  }

  .GDSNCIListRow_mini {
    height: $row-height-small;
  }
}

.GDSNCIListRow_smally {
  font-size: $font-size-small;
}

.GDSNCIListRow_boldy {
  font-weight: bolder;
}

.GDSNCIListRow_actions {
  float: right;
}

.GDSNCIListDetailsIconDoc {
  margin-left: 10px;
  cursor: pointer;

  .hidden {
    visibility: hidden;
  }
}

.GDSNCIListDetailsIcon {
  margin-left: 10px;
  font-size: 24px;
  cursor: pointer;

  .hidden {
    visibility: hidden;
  }
}
