@import '~@alkem/react-styles/src/vars';

$row-height-small: 32px;
$row-height-middle: 48px;
$row-height: 64px;

.EndpointListRowWrapper {
  margin: $padding-small-vertical 0;

  .EndpointListRow {
    cursor: auto;
    width: 100%;

    background-color: white;
    border: 1px solid $gray-border;
    height: $row-height;
    align-items: center;

    &:hover {
      background-color: $alk-background-hover;
    }

    &.active {
      background-color: $brand-primary-active;
      color: white;
    }
  }
}

.EndpointListRow_smally {
  font-size: $font-size-small;
}

.EndpointListRow_boldy {
  font-weight: bolder;
}

.EndpointListRow_actions {
  text-align: right;
}

.EndpointDetailsIcon {
  cursor: pointer;
  display: inline-block;

  .hidden {
    visibility: hidden;
  }
}

.EndpointFilesRow {
  cursor: auto;
  width: 100%;

  margin-right: 20px;

  background-color: white;
  height: $row-height-small;
  align-items: center;
  border-bottom: 1px dashed $gray-border;
  &.EndpointFilesRow__Search {
    height: $row-height-small * 1.5;
    .EndpointFilesRow_Navigation {
      display: inline-block;
      .EndpointFilesRow_NavigationSeparator {
        width: 15px;
        display: inline-block;
      }
    }
  }
}

.EndpointHandlerRowScroll {
  overflow-x: auto;
}

.EndpointFileProcessable {
  color: $brand-success;
}

.EndpointFileUnProcessable {
  color: $brand-danger;
}

.EndpointHandlerRowWrapper {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  font-size: $font-size-small;

  .EndpointHandlerRow {
    width: 100%;

    background-color: white;
    border-bottom: 1px dashed $gray-border;
    height: $row-height-small;
    align-items: center;

    &.EndpointHandlerRow__Header {
      font-weight: bolder;
    }
  }
}

.EndpointListRow_StatusBar {
  height: 100%;
  padding-left: 0;
}

.EndpointHandlerListRow_StatusBar {
  height: 100%;
  padding-left: 0;
}
