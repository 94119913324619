@import '~@alkem/react-styles/src/vars';

.IndexerPumpConfigList {
  margin: 2em;
}

.IndexerPumpConfig__createBtn {
  float: right;
}

.IndexerPumpConfig__row,
.IndexerPumpConfig__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5em;
}

.IndexerPumpConfig__row {
  background-color: white;
  border: 1px solid $gray-border;
  min-height: 4em;
}

.IndexerPumpConfig__header {
  min-height: 3em;
}

.IndexerPumpConfig__column {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  margin-right: 0.5em;

  &--title {
    font-size: large;
    font-weight: bold;
  }
}

.IndexerPumpConfig__source {
  flex-basis: 25%;
}

.IndexerPumpConfig__status {
  flex-basis: 15%;
}

.IndexerPumpConfig__data {
  flex-basis: 10%;
}

.IndexerPumpConfig__actions {
  flex-grow: 1;
  justify-content: right;
}

.IndexerPumpConfig__status--ok {
  color: $brand-success;
}
.IndexerPumpConfig__status--ko {
  color: $brand-warning;
}
