@import '~@alkem/react-styles/src/vars';

.FieldBulkEdit__addAttributes {
  margin-top: $margin-base;
  display: flex;
  > * + * {
    margin-left: $margin-base-horizontal;
  }
}

.FieldBulkEdit__attributes {
  margin-top: $margin-base;
}
