@import '~@alkem/react-styles/src/vars';

.ValidationRulesFilters {
  .Autocomplete {
    min-width: unset;
  }
  .ReactUiFilter__filtersBlock.card {
    padding: 0.7rem 0;

    .ReactUiFilter__filter {
      margin: 0 1rem;
      padding: 1rem 0 0;
      width: auto;
    }
    .ReactUiFilter__filterTitle {
      margin-top: 0.5rem;
    }
  }
}

.ValidationFilter__Autocomplete {
  padding: 16px 0;
}

.DashboardBlock {
  background-color: white;
  &.secondary {
    background-color: $alk-total;
    color: white;
    .TagsInput__tag {
      color: white;
      border-color: white;
    }

    .TagsInput__delete {
      background-color: $alk-total;
      color: $brand-white;
    }
  }
  .DashboardBlock__hidden_overflow {
    overflow-y: hidden;
  }

  .DashboardBlock__title {
    padding: 24px 24px 0px 24px;
    font-weight: 500;
    font-size: 22px;
  }

  .DashboardBlock__children {
    padding: 0 24px 16px 24px;
    .buttons {
      justify-content: flex-end;
    }
  }

  .DashboardBlock__expandable {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-top: 1px solid lightgray;
    cursor: pointer;
  }

  .ReactUiFilter__filter {
    padding-bottom: 0;
  }
}

.ActiveFilterLabel_fullWidth {
  width: 100%;
}
