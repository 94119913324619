@import '~@alkem/react-styles/src/vars';
.RuleSet {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex: 1;
  margin-right: $margin-base-horizontal;

  > * {
    flex-shrink: 0;
    flex-grow: 0;
    margin: 0 2em;
    &:first-child {
      flex-shrink: 1;
      flex-grow: 1;
    }
  }

  .DateRangePicker,
  .DateRangePicker__Month {
    margin: 0;
  }

  .deadline-toggleButton {
    margin: 0 0 0 1em;
  }

  .deadline-tooltip {
    margin-left: 0.5em;
  }
}
