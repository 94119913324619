@import '~@alkem/react-styles/src/vars';

.OrganizationGroup__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.OrganizationGroup__add {
  margin-left: $half-margin-base-horizontal;
  font-size: x-large;
  color: $brand-primary;
  &:hover {
    cursor: pointer;
    color: $brand-primary-btn-hover;
  }
}
