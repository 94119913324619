@import '~@alkem/react-styles/src/vars';

.ReferentialPictures__searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReferentialPictures__searchContainer .Search {
  margin: 0 0 0 1rem;
}

.ReferentialPictures__searchContainer .Autocomplete {
  background: white;
  border: 1px solid $gray-darker;
  border-radius: $border-radius-base;
  .Select__inputbox {
    border: none;
  }
}
