@import '~@alkem/react-styles/src/vars';

.KindDashboardView__body {
  overflow-x: hidden;
}

.KindDashboardView__title {
  display: flex;
  padding-right: $padding-base-horizontal;
  justify-content: space-between;
  align-items: center;
}

.KindDashboardView__content {
  margin: $margin-base $margin-base-horizontal $margin-base
    $margin-base-horizontal;
  height: calc(100vh - #{$large-header-height} - #{$margin-base * 2});
}

.KindDashboardView__panel--right {
  flex-grow: 1;

  .GenericTable__row {
    position: relative;
    padding-left: 30px;
    .Checkbox {
      position: absolute;
      left: 15px;
    }
  }
}

.KindDashboardView__actions {
  * + * {
    margin-left: $margin-base-horizontal;
  }
  .Checkbox {
    font-size: 15px;
  }
}
