@import '~@alkem/react-styles/src/vars';

.ValidationRuleHeader {
  grid-area: header;

  > div {
    align-self: center;
  }

  .ValidationRuleHeader__actions {
    display: flex;
    flex-direction: row;
  }

  .ValidationRuleHeader__bypassable {
    border-color: $brand-highlight;
    color: $brand-highlight;
    background-color: lighten($brand-highlight, 25);
    cursor: auto;
  }

  .ValidationRuleHeader__title {
    font-size: 1.05rem;
    flex-grow: 1;
  }

  .btn {
    margin: 0 0.25rem;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
  }

  .btn.ValidationRuleHeader__duplicate .btn.ValidationRuleHeader__delete {
    padding: 0.25rem;
    width: 2rem;
    height: 2rem;
    &:hover {
      background-color: $alk-background-hover;
    }
  }

  .ValidationRuleStatus.btn {
    font-weight: bolder;
    &-success {
      border-color: lighten($brand-success, 35);
      color: $brand-success;
      background-color: lighten($brand-success, 35);
    }

    &-danger {
      border-color: lighten($brand-danger, 35);
      color: $brand-danger;
      background-color: lighten($brand-danger, 35);
    }
  }

  .save-btn-warning {
    border-color: $alk-yellow;
    background-color: $alk-yellow;
    &.disabled {
      border-color: lighten($alk-yellow, 35);
      background-color: lighten($alk-yellow, 35);
    }
    &:not(.disabled):hover {
      background-color: lighten($alk-yellow, 15);
    }
  }
}
