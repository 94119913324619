@import '~@alkem/react-styles/src/colors';

.Modal__body > .FieldsReadOnlySettingEditModal__body {
  padding: 0;
}

.FieldsReadOnlySettingEditModal__body {
  overflow-x: auto;
  height: 100%;
  padding: 2rem;
}

.FieldsReadOnlySettingEditModal__line {
  display: flex;
  align-items: center;
  .Autocomplete {
    padding: 0 0 0 0.5rem;
  }
}

.FieldsReadOnlySettingEditModal__deleteButton {
  color: $gray;
  cursor: pointer;
  font-size: larger;
  padding: 0 0 0 0.5rem;
}

.FieldsReadOnlySettingEditModal__addButton {
  color: $alk-green;
  cursor: pointer;
  font-size: x-large;
  padding: 0 0 0 0.5rem;
}

ul.FieldsReadOnlySettingEditModal__lines {
  li {
    display: flex;
    align-items: center;
  }
  li.FieldsReadOnlySettingEditModal__linesSeparator {
    height: 1rem;
  }
}

.FieldsReadOnlySettingEditModal__empty {
  color: $gray-light;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0.5rem;
}
