@import '~@alkem/react-styles/src/vars';

.RightPanelHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.RightPanelHeader__label {
  font-size: 1.2rem;
  > * + * {
    margin-left: $half-margin-base-horizontal;
  }
}

.RightPanelHeader__status {
  font-size: 1.2rem;
  color: #c7254e;
  font-weight: 400;
}

.RightPanelHeader__actions {
  > * + * {
    margin-left: $half-margin-base-horizontal;
  }
}
