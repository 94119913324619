@import '~@alkem/react-styles/src/vars';

.ValidationRuleEditor {
  .ValidationRuleEditor__header {
    background-color: white;
    padding: 1rem 1rem 1rem 2rem;
    gap: 1rem;
    position: sticky;
    top: 0;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    z-index: 2;
  }
  .ValidationRuleBlock {
    border-color: white;
    margin: 1rem 0;
    padding: 1rem 1rem 0.5rem 2rem;
    background-color: white;
    overflow-y: auto;
    .ValidationRuleBlock__title {
      margin-bottom: 0.5rem;
    }
    h2 {
      font-weight: bold;
      font-size: larger;
    }
    small {
      color: $brand-default-disabled;
    }
    .InputWithLabel {
      flex-grow: 1;
      .InputWithLabel__label {
        flex-basis: 15%;
        text-align: right;
      }
      .InputWithLabel__input {
        flex-basis: 85%;
        max-width: 85%;
      }
      .NoRuleSetSelected {
        .Select__inputbox {
          border: 1px solid $alk-red;
        }
      }
    }

    &.RuleSetValidationRuleBlock {
      overflow-y: visible;
    }

    .RuleApplicationBlock__message__warning {
      i {
        color: $alk-yellow;
      }
      border-radius: 6px;
      padding: 0 5px;
      align-self: center;
    }

    .RuleApplicationBlock__packagingTypes {
      .InputWithLabel__input {
        display: flex;
        align-self: center;
      }
    }

    .RuleApplicationBlock__loading {
      span {
        align-self: center;
        margin-left: 5px;
      }
    }
  }

  .ValidationRuleOrganizations {
    max-height: 600px;
  }
}
