@use 'sass:math';

@import '~@alkem/react-styles/src/vars';

.OrganizationUsesFieldsView {
  padding: $padding-base-vertical $padding-base-horizontal;
  .Autocomplete {
    background-color: $brand-white;
  }
}

.OrganizationUsesFieldsView__fields {
  margin-top: $margin-base;
  background-color: $brand-white;
  border: 1px solid $gray-light;
  padding: $padding-base-vertical $padding-base-horizontal;
}

.OrganizationUsesFieldsView__Spinner {
  height: 50vh;
}
.OrganizationUsesFieldsView__header {
  display: flex;
  justify-content: space-between;
}

.OrganizationUsesFieldsView__ruleSet,
.OrganizationUsesFieldsView__field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: math.div($half-margin-base, 2);
  border: 1px solid $gray-light;
  padding: $padding-base-vertical;
  background-color: $gray-lightest;
}

.OrganizationUsesFieldsView__nofields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: math.div($half-margin-base, 2);
  padding: $padding-base-vertical;
}

.OrganizationUsesFieldsView__fieldName {
  flex: 0;
  margin-right: $margin-base-horizontal;
}

.OrganizationUsesFieldsView__overridable {
  margin-right: $margin-base-horizontal;
}

.OrganizationUsesFieldsView__fieldRules {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ApplicableKinds {
    margin: 0;
    &:not(:last-child) {
      margin-right: 1em;
    }
  }
  margin-right: $margin-base-horizontal;
}

.OrganizationUsesFieldsView__delete {
  cursor: pointer;
  font-size: x-large;
  color: $gray-light;
}

.OrganizationUsesFieldsView__fieldAutocomplete {
  margin-top: $half-margin-base;
}

.OrganizationUsesFieldsView__Actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
