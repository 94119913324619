@import '~@alkem/react-styles/src/vars';

.ReferentialGroup__row {
  display: flex;
  justify-content: space-between;
}

.ReferentialsList__createGroup {
  display: flex;
  & button {
    margin-left: 0.5em;
  }
}

.ReferentialGroup__row_delete--revertDelete {
  color: $brand-danger;
  cursor: pointer;
}

.ReferentialGroup__row_delete--acceptDelete {
  color: $brand-primary;
  cursor: pointer;
}

.ReferentialGroup__row_delete {
  min-width: 2em;
  display: flex;
  justify-content: flex-end;
}

.ReferentialGroup__rows {
  margin-top: 20px;
}
