@import '~@alkem/react-styles/src/vars';

$navbar-height: 40px;
$header-height: 50px;

.OrganizationPage__header {
  position: fixed;
  top: $navbar-height;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $header-height;
  background-color: $brand-white;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
  z-index: 1;
  padding: 0 1rem;
}

.OrganizationPage__title {
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
  padding-left: 1rem;
}

.OrganizationPage__actionLeft,
.OrganizationPage__actionRight {
  display: flex;
  align-items: center;
}

.OrganizationPage__container {
  margin-top: calc(#{$navbar-height} + #{$header-height} + 1rem);
  padding: 0 2rem;
}

.OrganizationPage__spinner {
  text-align: center;
  margin-top: 5rem;
}

.OrganizationPage__content {
  display: flex;
}

.OrganizationPage__column {
  width: 50%;
  padding: 0 1rem;
}

.OrganizationPageBlock {
  background-color: $brand-white;
  border: 1px solid $gray-light;
  padding: 1rem;
  margin-bottom: 2rem;
}

.OrganizationPageBlock__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  .mdi {
    font-size: smaller;
    color: $gray-medium;
  }
}

.OrganizationPageBlock__edited {
  font-style: italic;
  color: $gray-light;
  margin-right: 0.5rem;
  i {
    color: orange;
    margin-right: 0.2rem;
  }
}

.OrganizationPage__save,
.OrganizationPage__reindex {
  margin-right: 2rem;
}
