@import '~@alkem/react-styles/src/vars';

.Search {
  position: relative;
  width: 100%;
  max-width: 32rem;
  margin: 0 auto;
  &.Search--wide {
    max-width: none;
  }
}

.Search__input {
  border: 1px solid $gray-darker;
  border-radius: $border-radius-base;
  white-space: nowrap;
  padding: 0.75rem 2.4rem 0.75rem 2.6rem;
  width: 100%;
  font-size: 1rem;
  &.Search__input--small {
    height: 2.4rem;
  }
}

.mdi.Search__icon {
  position: absolute;
  font-size: 2rem;
  line-height: 2rem;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%) scaleX(-1);
}

.Search__icon--small {
  font-size: 1rem;
  line-height: 1rem;
}

.ClearSearch__icon {
  position: absolute;
  cursor: pointer;
  font-size: 2rem;
  line-height: 2rem;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  &.ClearSearch__icon--small {
    font-size: 1rem;
    line-height: 1rem;
  }
}

.TooltipSearch__icon {
  position: absolute;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 1.5rem;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  &.TooltipSearch__icon--small {
    font-size: 1rem;
    line-height: 1rem;
  }
}
