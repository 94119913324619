@import '~@alkem/react-styles/src/vars';

.TransactionFilter__Entities {
  margin: $padding-base-vertical $padding-base-horizontal;

  ul {
    padding: 0.2rem 1rem;

    .mdi.mdi-close {
      color: $brand-danger;
    }
  }
}

.TransactionFilter__InvalidEntity {
  width: 100%;
  text-align: right;
  color: $brand-danger;
}
