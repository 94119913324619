@use 'sass:math';

@import '~@alkem/react-styles/src/vars';

.PublicLayout {
  position: relative;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 330px + $margin-base * 2;
  transition: all 0.6s;
  background: $brand-white;

  .card {
    border: none;
    background: $brand-white;
  }
}

.PublicLayout__header {
  padding: $margin-base * 2 0 $margin-base * 2 0;
  img {
    height: 120px;
  }
}

.PublicLayout__footer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 50px;
}

.PublicLayout__footerTitle {
  font-size: 30px;
  margin: 0 0 math.div($margin-base, 2) 0;
}

.PublicLayout__footerIcons {
  margin: $margin-base 0;
}

.PublicLayout__footerIcon {
  color: $brand-primary;
  background: $gray-dark;
  border-radius: 50%;
  margin: 0 math.div($margin-base, 2);
  width: 32px;
  height: 32px;
  text-align: center;
  transition: 0.3s all;
  text-decoration: none;
  &:before {
    font-size: 18px;
  }
  &:hover {
    color: white;
    text-decoration: none;
  }
}

.mdi.PublicLayout__footerIcon {
  display: inline-block;
  line-height: 32px;
  font-size: 0;
}

.PublicLayout__footerLinks {
  margin-top: $margin-base;
  margin-right: 0;
  padding: 0;
  text-align: center;
}

.PublicLayout__footerLink {
  display: inline-block;
  list-style: none;
  margin: 0 math.div($margin-base, 2);
  font-weight: 500;
  font-size: 16px;
  a {
    text-decoration: none;
    transition: 0.3s all;
    &:hover {
      text-decoration: none;
    }
  }
}

.PublicLayout__footerLogo {
  border-top: 1px solid $gray;
  margin: 0 auto;
  padding: 15px 0;
  img {
    height: 30px;
  }
}

.PublicLayout__footerAddress {
  font-size: 13px;
}
