@import '~@alkem/react-styles/src/vars';

.Navbar.navbar {
  padding: 0 $container-fluid-margin;
  &.bg-inverse {
    background: $navbar-inverse-bg;
    color: $navbar-inverse-color;
  }

  &.Navbar--PPR.bg-inverse {
    background: $brand-secondary;
  }
}

.Navbar__logoLink.navbar-brand {
  padding: 0;
  margin: 0 1rem 0 0;
}

.Navbar__logo {
  height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
}
