@import '~@alkem/react-styles/src/colors';

.CatalogAdvancedFilterPagination {
  display: flex;
  align-items: center;

  i.mdi {
    color: $brand-secondary;
    cursor: pointer;

    &:hover:not([disabled]) {
      color: $brand-primary;
    }

    &[disabled] {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.CatalogAdvancedFilterPagination__pages {
  flex: 1;
  text-align: center;
}
