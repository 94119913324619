@use 'sass:math';

@import '~@alkem/react-styles/src/vars';

.ApplicablePackagingTypes {
  margin-top: $half-margin-base;
  margin-bottom: $half-margin-base;
  .Checkbox {
    margin-right: $half-margin-base-horizontal;
  }
  &.ApplicablePackagingTypesCompact {
    margin-top: math.div($half-margin-base, 2);
    margin-bottom: math.div($half-margin-base, 2);
    border: 1px dashed $gray-light;
    border-radius: 4px;
    background-color: $gray-lighter;
    padding: math.div($half-margin-base, 2);
  }
}
