@import '~@alkem/react-styles/src/vars';

.Runner {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .Runner__Top {
    display: flex;
    flex-direction: row;
  }

  .Runner__Headers {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    padding: 1rem;
    flex-grow: 1;

    // Need to set a minimum other Ace Editor is not displaying properly
    min-height: 200px;
  }

  .Runner__Headers_Editor {
    flex-grow: 1;
    border-style: dotted;
    border-width: 1px;
  }

  .Runner_Payload {
    display: flex;
    flex-grow: 1;
    font-size: 1rem;
    // Need to set a minimum other Ace Editor is not displaying properly
    min-height: 500px;
  }

  .Runner__Payload_Editor {
    flex-grow: 1;
  }

  .Runner__Inputs {
    padding: 1rem;
  }

  .Runner__Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Runner__dropzone {
    padding: 0.5rem 1rem;
    background-color: white;
    border: 1px solid $brand-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.Runner__dropzone--active {
      background-color: lighten($brand-secondary, 40%);
      border-color: $brand-secondary;
    }
  }
}
