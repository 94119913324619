@import '~@alkem/react-styles/src/vars';

.CleanProductsModal__Section {
  margin-top: 1rem;
}

.CleanProductsModal__Product {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem;

  .mdi.mdi-delete {
    cursor: pointer;
    color: $gray-light;

    &:hover {
      color: $gray-dark;
    }
  }
}
