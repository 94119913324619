@import '~@alkem/react-styles/src/vars';

.GDSNExportMappingsElementSelector__child {
  margin-left: $margin-base-horizontal;
}
.GDSNExportMappingsElementSelector__modalButtonsContainer {
  display: flex;
  justify-content: center;
  flex-flow: column;
}
.GDSNExportMappingsElementSelector__childNamesContainer {
  display: flex;
  cursor: pointer;
}
