@import '~@alkem/react-styles/src/vars';

.GDSNExportMappingsListElement__moduleChild {
  margin-left: $margin-base-horizontal;
}
.GDSNExportMappingsListElement__listDisplay {
  display: flex;
}
.GDSNExportMappingsListElement__listDisplay .mdi,
.GDSNExportMappingsListElement__listDisplay
  .GDSNExportMappingsListElement__elementName {
  cursor: pointer;
}
