@import '~@alkem/react-styles/src/vars';

#create-root {
  margin-left: $half-margin-base-horizontal;
}

.LeftPanelTree {
  border-left: none;
  cursor: pointer;
  overflow: hidden;
}
