@import '~@alkem/react-styles/src/vars';

.ProductMaintenance {
  margin: 20px;
  padding: 20px;
  background: white;
}

.ProductMaintenance__OrganizationSearch {
  margin-right: 20px;
}

.ProductMaintenance__actions {
  > * + * {
    margin-left: $half-margin-base-horizontal;
  }
}

.ProductMaintenance__field {
  margin: 10px 0;

  .error {
    margin-top: 5px;
  }
}

.ProductMaintenance__saveBloc {
  > * {
    margin-left: 5px;
  }

  input {
    width: 310px;
  }

  .error {
    margin-top: 2px;
  }
}

.ProductMaintenance__save {
  height: fit-content;
}
