@import '~@alkem/react-styles/src/vars';

.OrganizationGroupsItem {
  flex-basis: 20%;
  padding: $padding-base-vertical $padding-base-horizontal;
}

.OrganizationGroupsItem__container {
  border: 1px solid $gray-border;
  background-color: white;
  position: relative;
}

.OrganizationGroupsItem__picture {
  margin: $half-margin-base $half-margin-base-horizontal;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OrganizationGroupsItem__edit {
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
}

.OrganizationGroupsItem__name {
  text-align: center;
  margin-bottom: $half-margin-base;
}
