@import '~@alkem/react-styles/src/vars';

.OrganizationRow {
  &.OrganizationRow--first {
    margin-top: 30px;
  }
}

.OrganizationRow--disabled {
  background-color: $gray-really-light;
  color: $gray-medium;
}

.OrganizationRow__header {
  display: block;
  position: absolute;
  bottom: 100%;
  margin-bottom: 0.5rem;
  color: $gray-medium;
}

$row-type-width: 130px;
$row-users-width: 100px;
$row-networks-width: 150px;
$row-last-activity-width: 130px;
$row-action-width: 150px;

.OrganizationRow__status {
  width: 5px;
  height: 100%;

  &.OrganizationRow__status--0 {
    background-color: $brand-danger;
  }
  &.OrganizationRow__status--1 {
    background-color: $brand-success;
  }
  &.OrganizationRow__status--2 {
    background-color: $brand-success;
  }
  &.OrganizationRow__status--3 {
    background-color: #000;
  }
}

.OrganizationRow__label {
  flex: 1;
  min-width: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
}

.OrganizationRow__name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 2.4rem;
  overflow: hidden;
  line-height: 1.2rem;
  font-weight: 400;
}

.OrganizationRow__type {
  flex: 0 0 $row-type-width;
  min-width: 1px;
}

.OrganizationRow__users {
  flex: 0 0 $row-users-width;
  min-width: 1px;
}

.OrganizationRow__networks {
  flex: 0 0 $row-networks-width;
  min-width: 1px;
}

.OrganizationRow__lastActivity {
  flex: 0 0 $row-last-activity-width;
  min-width: 1px;
  font-size: 400;
}

.OrganizationRow__action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 $row-action-width;

  button {
    border: none;
    background: none;
    margin: 0;
    padding: 0.1rem 0.25rem;
    font-size: 2rem;
    color: $brand-secondary;

    &:hover {
      color: $brand-primary;
    }
  }

  .OrganizationRow__btn + .OrganizationRow__btn {
    margin-left: 0.25rem;
  }

  .OrganizationRow__btn.OrganizationRow__btn--warn {
    color: $brand-danger;

    &:hover {
      color: lighten($brand-danger, 10%);
    }
  }

  .OrganizationRow__btn.OrganizationRow__btn--success {
    color: $brand-success;

    &:hover {
      color: lighten($brand-success, 10%);
    }
  }
}
