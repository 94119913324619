@import '~@alkem/react-styles/src/vars';

.Login__submit {
  margin: $half-margin-base 0;
}

.Login__title {
  margin-bottom: $margin-base;
}

.Login__input-email {
  margin-bottom: $half-margin-base;
}

.Login__error {
  a:not([href]):not([tabindex]) {
    cursor: pointer;
    text-decoration: underline;
  }
}

.Login__signupLink {
  margin-top: $margin-base;
  margin-bottom: 0;
}

.text-center {
  text-align: center;
}
