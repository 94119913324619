@import '~@alkem/react-styles/src/vars';

.FieldTreeItem {
  margin-left: $margin-base-horizontal;
}

.FieldTreeItem__line {
  display: flex;
  > i {
    font-size: large;
    cursor: pointer;
  }
}

.FieldTreeItem__label {
  cursor: pointer;
  overflow: hidden;
}

.FieldTreeItem__chevron--hidden {
  color: $brand-white;
}
