@import '~@alkem/react-styles/src/vars';

.RuleCreationModal {
  /* The following as a quick fix for selector drawers not to be cropped by modal boundaries */
  .RuleCreationModal__body {
    height: 350px;
  }
  .ChoiceTree.ChoiceTree--scrollable {
    max-height: 200px;
  }
  .TurboSelect__menu-list {
    max-height: 150px;
  }

  .RuleCreationModal__modalBody {
    > * + * {
      margin-top: $half-margin-base;
    }

    .DateFilter__wrapped {
      margin: 0;
    }

    .RuleEntityTypeHelp {
      font-style: italic;
      white-space: nowrap;
    }
  }
}
