@import '~@alkem/react-styles/src/vars';

.GDSNImportMappingsItemFallbackProperties__sectionTitle {
  margin-top: 1.25em;
  border-bottom: 1px solid $gray-light;
  font-weight: 500;
}

.GDSNImportMappingsItemFallbackProperties__buttonContainer {
  margin-top: 0.25em;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
