@import '~@alkem/react-styles/src/vars';

.UserUpdateForm {
  width: 60vw;
}

.UserUpdateForm__line {
  display: flex;
  padding-bottom: 1rem;
  align-items: center;

  &.UserUpdateForm__line--settings {
    align-items: flex-start;
  }

  &.UserUpdateForm__line--invisible {
    visibility: hidden;
  }
}

.UserUpdateForm__label {
  flex: 1;
  padding-right: 1rem;
  text-align: right;
}

.UserUpdateForm__input {
  flex: 3;

  &.UserUpdateForm__input--settings {
    display: flex;
    flex-direction: column;
  }
}

.UserUpdateForm__textInput {
  width: 100%;
}

.UserUpdateForm__radio {
  & > span {
    padding-left: 0.5rem;
  }
}

.UserUpdateForm__title {
  font-size: x-large;
  margin-bottom: 10px;
  color: $gray-medium;
}
