@import '~@alkem/react-styles/src/vars';

.ReferentialInput {
  .AddButton {
    margin-top: 0.25em;
  }
}

.ReferentialInput__actions {
  margin-top: 0.25em;
  > * + * {
    margin-left: $margin-base-horizontal;
  }
}

.ReferentialInput__warning {
  margin-top: 0.2em;
  color: $brand-danger;
}
