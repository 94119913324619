@import '~@alkem/react-styles';

.ImportDashboardView_Workflow {
  font-size: x-large;
  text-align: center;
  background-color: $gray-lighterer;
  padding: 10px;
  cursor: pointer;
  border-left: solid;
  &:hover {
    background-color: $gray-medium;
  }
}

.ImportDetailsRow__Header_icon {
  float: right;
  cursor: pointer;
}
