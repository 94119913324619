@import '~@alkem/react-styles/src/vars';

.NodeList {
  margin: 0 1rem;
}

.card.NodeList__Node {
  border-radius: $border-radius-small;
  padding: 1rem;
}

.NodeList__search {
  display: flex;
  padding: 0.5rem;
  align-items: center;

  .InputField {
    flex: 1;
    margin-right: 0.2rem;
  }

  .SimpleSelect {
    width: 150px;
    margin: 0 0.4rem;
  }
}
