@import '~@alkem/react-styles/src/vars';

.ValidationDocumentationModal > .ValidationDocumentationModal__body {
  max-width: initial;
}

.ValidationDocumentationModal__item {
  border: 1px solid $gray-lighterer;
  border-radius: $border-radius-base;
  padding: $padding-base-vertical $padding-base-horizontal;
  + .ValidationDocumentationModal__item {
    margin-top: $half-margin-base;
  }
}

.ValidationDocumentationModal__inlineValue {
  margin-left: $half-margin-base-horizontal;
  > pre {
    display: inherit;
    margin-bottom: 0;
  }
}

.ValidationDocumentationModal__indented {
  margin-top: $half-margin-base;
  margin-left: $margin-base-horizontal;
}

.ValidationDocumentationModal__doc {
  white-space: pre;
}

.ValidationDocumentationModal__Example {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border: 1px solid #b8b8b8;
  background-color: white;
}

.ValidationDocumentationModal__ExampleLabel {
  width: 7%;
  min-width: 6rem;
  text-align: right;
  margin-right: 1rem;
}
