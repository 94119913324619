@import '~@alkem/react-styles/src/vars';

.SelectedFilters {
  background-color: $alk-total;
  color: white;
  padding: 20px $margin-base;
  margin: 0 0 5% 0;
}

.SelectedFilters__title {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 5px 0;
  padding: 0;
}

.SelectedFilters__removeAll,
.SelectedFilters__noFilters {
  color: white;
  font-size: 0.875rem;
}

.SelectedFilters__removeAll {
  text-decoration: underline;
}

.SelectedFilters__removeAll:hover {
  font-weight: 500;
  cursor: pointer;
}

.SelectedFilters__chipsList {
  margin: 5px 0 5px 0;
}

.SelectedFilters__chip {
  position: relative;
  border: 1px solid white;
  margin: 5px;
  margin-left: 0;
  color: #fff;
  background-color: $brand-primary;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: default;
  padding: 0.175rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.SelectedFilters__chip > label {
  margin-bottom: 0;
}

.SelectedFilters__removeFilter {
  position: absolute;
  border-radius: 100%;
  background: $brand-primary;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
}
