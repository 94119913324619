@use 'sass:math';

@import '~@alkem/react-styles/src/vars';

.ProductHistoryDiffCard__separator {
  color: $gray-light;
  font-size: smaller;
}

.ProductHistoryDiffCard__labelGroup {
  margin-left: $margin-base;
}

.ProductHistoryDiffCard__empty {
  color: $gray-light;
  font-style: italic;
}

.ProductHistoryDiffCard__complexLabel {
  display: flex;
}

.ProductHistoryDiffCard__row {
  padding: math.div($margin-base, 4) 0;
  border-top: 1px solid $gray-light;
}
