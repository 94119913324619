@import '~@alkem/react-styles/src/vars';

.FieldOrganizations {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  > .Button {
    margin-left: $half-margin-base-horizontal;
  }
  &:not(:last-child) {
    margin-right: 110px;
  }
}

.FieldOrganizations__add {
  margin-bottom: $margin-base;
}

.FieldOrganizations__item {
  display: flex;
  margin-top: 0.25em;
  align-items: center;
  > * + * {
    margin-left: $margin-base-horizontal;
  }

  .mdi.mdi-delete {
    cursor: pointer;
    color: $gray-light;

    &:hover {
      color: $gray-dark;
    }
  }
}
