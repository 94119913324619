@import '~@alkem/react-styles/src/vars';

.Rabbitmq__Header {
  display: flex;
  align-items: center;

  margin: 1rem 0;
}

.Rabbitmq__Number {
  flex: 1;
  text-align: center;
}

.Rabbitmq__Queue {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1rem;
}

.PayloadModal {
  .RabbitmqPayloadModal__Body {
    height: 200px;
    display: inline-block;
  }

  .RabbitmqMessage__Body {
    width: 49%;
    margin-right: 1%;
  }

  .RabbitmqHeader__Body {
    width: 50%;
  }
}

.PurgeModal__Info {
  margin-top: 1rem;
}

.PurgeModal__Regexp {
  display: flex;
}

.PurgeModal__Regexp input {
  width: 500px;
  margin-right: 1rem;
}

.PurgeModal__Footer {
  font-style: italic;
  color: $gray-medium;
}
