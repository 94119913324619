@import '~@alkem/react-styles/src/vars';

$stepColorSuceed: $brand-success;
$stepColorFailed: $brand-danger;
$stepColorError: $alk-red;
$stepColorSkipped: $brand-inprogress;

.RuleApplierOverview__Header {
  margin-bottom: 10px;
}

.RuleOverviewStep__Expression {
  color: black;
}

.RuleOverviewStep__Overview {
  border-left: 4px solid transparent;
  padding-left: 4px;

  .RuleOverviewStep__Status {
    text-align: center;
    padding-right: 15px;
    font-weight: bold;
  }
}

.RuleOverviewStep__Overview--passed {
  border-left-color: $stepColorSuceed;

  .RuleOverviewStep__Status {
    background: rgba($stepColorSuceed, 0.15);
  }
}

.RuleOverviewStep__Overview--skipped {
  border-left-color: $stepColorSkipped;

  .RuleOverviewStep__Status {
    background: rgba($stepColorSkipped, 0.15);
  }
}

.RuleOverviewStep__Overview--failed {
  border-left-color: $stepColorFailed;

  .RuleOverviewStep__Status {
    background: rgba($stepColorFailed, 0.15);
  }
}

.RuleOverviewStep__Overview--error {
  border-left-color: $stepColorError;

  .RuleOverviewStep__Status {
    background: rgba($stepColorError, 0.15);
  }
}

.RuleOverviewStep__OutcomeResult {
  color: black;
  background: white;
}

.RuleOverviewStep__Name {
  text-align: center;
}
