@import '~@alkem/react-styles/src/vars';

.DisplayGroupPickerModal__items {
  > * + * {
    margin-top: $half-margin-base;
  }
}

.DisplayGroupPickerModal__displayGroup {
  cursor: pointer;
}

.DisplayGroupPickerModal__displayGroup,
.DisplayGroupPickerModal__link {
  padding-bottom: $padding-base-vertical;
  border-bottom: 1px solid $gray-light;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.DisplayGroupPickerModal__firstPlaceholder {
  text-align: right;
}

.DisplayGroupPickerModal__footer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.DisplayGroupPickerModal__footerButtons {
  margin-left: $half-margin-base-horizontal;
}
