@import '~@alkem/react-styles/src/vars';

.FilterTreeLinkItem {
  position: relative;
  line-height: 1.3;
  padding-left: 19px;
  color: $brand-primary;
}

.FilterTreeLinkItem__btn.Button {
  width: 100%;
  padding: 0;
  white-space: normal;
  text-align: left;
}
