@import '~@alkem/react-styles/src/vars';

.GDSNImportMappingsItemFallbackProperty .InputWithLabel {
  margin-top: 0.25em;
}

.GDSNImportMappingsItemFallbackProperty .InputWithLabel__input {
  display: flex;
  flex-direction: column;
}

.GDSNImportMappingsItemFallbackProperty .InputTextarea {
  flex: 1;
}

.GDSNImportMappingsItemFallbackProperty__buttonContainer {
  margin-top: 0.25em;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.GDSNImportMappingsItemFallbackProperty__sectionTextbox {
  width: 100%;
  display: flex;
  margin-top: 0.25em;
}

.GDSNImportMappingsItemFallbackProperty__spansContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 5px;
  justify-content: center;
}

.GDSNImportMappingsItemFallbackProperty__span--enabled {
  cursor: pointer;
  display: flex;
  color: $brand-primary;
  margin-left: 5px;
}

.GDSNImportMappingsItemFallbackProperty__span--disabled {
  cursor: none;
  pointer-events: none;
  margin-left: 5px;
}

.GDSNImportMappingsItemFallbackProperty {
  border: 1px solid $gray-light;
  border-radius: $border-radius-large;
  margin-top: 0.25rem;
  padding: 0.25rem;
}
