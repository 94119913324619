@import '~@alkem/react-styles/src/vars';

.ListInputModal__form {
  .InputWithLabel {
    margin-bottom: 2rem;
  }

  .InputWithLabel__label {
    flex-basis: 10%;
    text-align: right;
  }

  .InputWithLabel__input {
    flex-basis: 90%;

    .InputField {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.ListInputModal__info {
  margin-bottom: 1.5rem;
}

.ListInputModal__index {
  flex-basis: 20%;
}

.ListInputModal__value {
  flex-basis: 80%;
}

.ListInputModal__actionIcon {
  cursor: pointer;
  font-size: x-large;
  color: $gray-light;
  margin-left: $half-margin-base-horizontal;
}

.ListInput .InputWithLabel__input {
  max-width: 87%;
}

.ListInputModal__validationField {
  margin-bottom: $half-margin-base-horizontal;

  .ListInputModal__validationField__data {
    max-width: calc(100% - 30px);
  }

  pre {
    flex: 1;
    margin-left: 0.3rem;
    margin-bottom: 0;
    max-width: 92%;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

.ListInputModal__footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Checkbox {
    margin-left: 15px;
  }
}
