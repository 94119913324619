@import '~@alkem/react-styles/src/vars';

.GDSNExportMappingsItemConstants__sectionTitle {
  margin-top: 1.25em;
  border-bottom: 1px solid $gray-light;
  font-weight: 500;
}

.GDSNExportMappingsItemConstant {
  border: 1px solid $gray-light;
  margin: 1.25em;
}

.GDSNExportMappingsItemConstants .InputWithLabel {
  margin: 0.25em;
}

.GDSNExportMappingsItemConstant__button {
  text-align: right;
  padding: 0.25em;
}
