@import '~@alkem/react-styles/src/vars';

.EdaRmqReplayerQueue {
  margin-bottom: 1em;
  background-color: white;
}

.EdaRmqReplayerQueue__row {
  display: flex;
  flex-direction: row;
  justify-content: justify-content;
  align-items: center;
  padding: 5px;
}

.EdaRmqReplayerQueue__chevron.Button {
  font-size: 2em;
  padding: 0;
  margin: 0;
  border: none;

  &:focus {
    outline: none;
  }
}

.EdaRmqReplayerQueue__bottomSeparator {
  padding: 10px;
}

.EdaRmqReplayerQueue__name {
  flex: 1;
  padding: 0 10px;
  word-break: break-all;
}

.EdaRmqReplayerQueue__count {
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-weight: bold;
  color: $brand-danger;
  border-left: 1px dashed grey;
  border-right: 1px dashed grey;
  text-align: right;

  div:first-child {
    color: transparent;
    height: 0;
    overflow: hidden;
  }
}

.EdaRmqReplayerQueue__input {
  border: none;
  border-bottom: 1px solid grey;
}

.EdaRmqReplayerQueue__dangerousAction {
  color: $brand-danger;
}

button:disabled {
  .EdaRmqReplayerQueue__dangerousAction {
    color: $gray-light;
  }
}

.EdaRmqReplayerQueue__buttonIcon {
  padding-right: 5px;
}

.EdaRmqReplayerQueue__actions {
  display: flex;
  align-items: center;
}

.EdaRmqReplayerQueue__confirmQueyeName {
  max-width: 600px;
  word-break: break-all;
}
