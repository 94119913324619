@import '~@alkem/react-styles/src/vars';

.FieldHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FieldHeader__label {
  font-size: 1.2rem;
  > * + * {
    margin-left: $half-margin-base-horizontal;
  }
}

.FieldHeader__actions {
  > * + * {
    margin-left: $half-margin-base-horizontal;
  }
}
