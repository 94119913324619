@import '~@alkem/react-styles/src/vars';

.MediaOrganization__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  & > .Button {
    margin-left: 0.5em;
  }
}

.MediaOrganization__card {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  margin: 1em;
  border: 1px solid $gray-lighterer;
  background: $brand-white;
  &:first-child {
    margin-left: 0;
  }
}

.MediaOrganization__tip {
  font-style: italic;
  color: $gray-medium-dark;
}

.MediaOrganization__image {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MediaOrganization__container {
  background: inherit;
  border: inherit;
  padding: initial;
  margin: inherit;
}

.MediaOrganization__list {
  display: flex;
  align-items: stretch;
}
