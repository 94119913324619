@import '~@alkem/react-styles/src/vars';

.DisplayGroupView {
  padding: $padding-base-vertical $padding-base-horizontal;
  .Autocomplete {
    background-color: $brand-white;
  }
}

.DisplayGroupView__header {
  display: flex;
  justify-content: space-between;
  background-color: $brand-white;
  border: 1px solid $gray-lighterer;
  padding: $padding-large-vertical $padding-large-horizontal;
  margin-bottom: $margin-base;
}

.DisplayGroupView__path {
  display: inline-flex;
  align-items: baseline;
  i {
    font-size: 1.2rem;
  }
}

.DisplayGroupView__pathNode {
  cursor: pointer;
  font-size: 1.1rem;
  color: $brand-primary;
  &:hover {
    text-decoration: underline;
  }
}

.DisplayGroupView__content {
  display: flex;
  flex-direction: column;
  background-color: $brand-white;
  border: 1px solid $gray-lighterer;
  padding: $padding-large-vertical $padding-large-horizontal;
}

.DisplayGroupView__displayGroup {
  display: flex;
  align-items: baseline;
  font-size: 1.1rem;
  + .DisplayGroupView__displayGroup {
    margin-top: $half-margin-base;
  }
  + .DisplayGroupView__field {
    margin-top: $margin-base;
  }
  > * + * {
    margin-left: $half-margin-base-horizontal;
  }
}

.DisplayGroupView__displayGroupLabel {
  flex-grow: 1;
}

.DisplayGroupView__displayGroupView {
  margin-left: $margin-base-horizontal;
  margin-right: $margin-base-horizontal;
}

.DisplayGroupView__field {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid $gray-slightly-lighter;
  font-size: 1.1rem;
  + .DisplayGroupView__field {
    margin-top: $half-margin-base;
  }
  input {
    border: none;
  }
}

.DisplayGroupView__add {
  margin-top: $half-margin-base;
}

.DisplayGroupView__refreshCacheButton {
  margin-left: 1rem;
}
