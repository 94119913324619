@use 'sass:math';

@import '~@alkem/react-styles/src/vars';

.Referential__pagination {
  margin-top: $half-margin-base;
  margin-bottom: $half-margin-base;
  text-align: right;
  > span {
    margin-right: $margin-base-horizontal;
  }
  #referential-prev-page-btn {
    margin: 0;
  }
  #referential-next-page-btn {
    margin: 0;
    border-left: none;
  }
}

.Referential__entities {
  width: 100%;
  margin-top: $half-margin-base-horizontal;
}

.Referential__addEntity {
  margin-top: $margin-base;
}

.ReferentialEntity {
  + .ReferentialEntity {
    padding-top: $half-margin-base;
  }
}

.ReferentialEntity__input {
  overflow: hidden;
  padding-right: $half-margin-base-horizontal;
  padding-top: math.div($half-margin-base, 2);
  vertical-align: bottom;
  .form-control,
  .InputDisabled,
  .ace_editor {
    font-size: 0.9rem;
  }
  &--dirty {
    .form-control,
    .ace_editor {
      border: 1px solid $brand-highlight;
      background: $brand-highlight-disabled;
    }
    input,
    textarea {
      border: none;
    }
  }
  &--invalid {
    .form-control,
    .ace_editor {
      border: 1px solid $brand-danger;
      background: $brand-danger-hover;
    }
    input,
    textarea {
      border: none;
    }
  }
}

.ReferentialEntity__code {
  width: 100px;
}

.ReferentialEntity__rank {
  width: 50px;
}

.ReferentialEntity__data {
  width: 300px;
  font-family: $font-family-monospace;
}

.ReferentialDetail__header {
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  .Search {
    margin: 0;
  }
}

.ReferentialEntity__delete {
  vertical-align: middle;
  .mdi {
    font-size: x-large;
    color: $gray-light;
    &:hover {
      cursor: pointer;
      color: $gray-dark;
    }
  }
}

.Referentials__content {
  .btn + .btn {
    margin-left: $half-margin-base-horizontal;
  }
}
