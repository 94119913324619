@import '~@alkem/react-styles/src/vars';

.GDSNExportMappingsSimpleElementItemType .InputWithLabel {
  margin-top: 0.25em;
}

.GDSNExportMappingsSimpleElementItemType
  .InputWithLabel
  .InputWithLabel__label
  > label {
  margin-bottom: 0px;
}
