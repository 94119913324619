@import '~@alkem/react-styles/src/vars';

$user-disabled: $gray-lighter;
$user-pending: rgba(245, 182, 38, 0.2);
$user-relation-refused: rgba(245, 0, 0, 0.2);
$user-relation-pending: rgba(0, 0, 245, 0.2);

.OrganizationPageUsers__passwordError {
  &.__react_component_tooltip {
    background-color: $brand-danger;
  }
}

ul.OrganizationPageUsers__users {
  list-style: none;
  margin: 0;
  padding: 0;
}

li.OrganizationPageUsers_row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 40px;
  padding: 0.25rem 0;
  border-bottom: 1px solid $gray-light;

  &:last-of-type {
    border: none;
  }
  &--disabled {
    background-color: $gray-lighter;
  }
  &--pending {
    background-color: $user-pending;
  }
  &--relation-refused {
    background-color: $user-relation-refused;
  }
  &--relation-pending {
    background-color: $user-relation-pending;
  }
}

ul.OrganizationPageUsers__help {
  list-style: none;
  margin: 0 0 16px 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
}

li.OrganizationPageUsers__help {
  padding: 5px 10px;

  &--disabled {
    background-color: $user-disabled;
  }
  &--pending {
    background-color: $user-pending;
  }
  &--relation-refused {
    background-color: $user-relation-refused;
  }
  &--relation-pending {
    background-color: $user-relation-pending;
  }
}

.OrganizationPageUsers_columnUsername {
  width: 60%;
  padding-left: 0.5rem;
}

.OrganizationPageUsers_columnRole {
  width: 15%;
  text-align: center;
}

.OrganizationPageUsers_columnRoleBadge {
  padding: 0.25rem;
  color: $alk-red;
  border: 1px solid $alk-red;
  border-radius: $border-radius-large;
}

.OrganizationPageUsers_columnLogAs {
  width: 25%;
  text-align: right;
  padding-right: 0.5rem;
}

.OrganizationPageUserPagination {
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OrganizationPageUserPagination__button {
  cursor: pointer;
  font-size: x-large;
  color: $brand-primary;
}

.OrganizationPageUserPagination__button--disabled {
  color: $gray-light;
}

.OrganizationPageUserAdd {
  margin-top: 2rem;
  padding-top: $padding-large-vertical;
  padding-bottom: $padding-large-vertical;
  border: 1px dashed $brand-primary;
  color: $brand-primary;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  font-size: 1rem;
  justify-content: center;
}
