@import '~@alkem/react-styles/src/vars';

.GDSNExportMappingsSimpleSelector__simpleSelect {
  display: flex;
  align-items: center;
}

.GDSNExportMappingsSimpleSelector__reset-button {
  color: $brand-primary;
  margin-left: 10px;
}

.GDSNExportMappingsSimpleSelector__search {
  margin-bottom: 0.25em;
}

.GDSNExportMappingsSimpleSelector__simpleSelect > .SimpleSelect {
  flex: 1;
  .SimpleSelect_value {
    overflow-wrap: anywhere;
  }
}
