@use 'sass:math';

@import '~@alkem/react-styles';

body {
  overflow-x: hidden;
  overflow-y: auto;
}

.Navbar.navbar {
  height: 40px;
}

.flex {
  display: flex;
}

.flex-align-items--center {
  align-items: center;
}

.flex-align-items--baseline {
  align-items: baseline;
}

.flex-justify-content--center {
  justify-content: center;
}

.flex-justify-content--space-between {
  justify-content: space-between;
}

.flex-justify-content--end {
  justify-content: flex-end;
}

.flex-grow--1 {
  flex-grow: 1;
}

.text-decoration--line-through {
  text-decoration: line-through;
}

.grid {
  display: grid;
}

.justify-self--center {
  justify-self: center;
}

.btn-icon {
  cursor: pointer;
  color: $gray-light;

  &:hover {
    color: $gray-dark;
  }
}

span.mdi.Pagination__chevron {
  font-size: 1.5rem !important;
  line-height: 1.5rem;
}

ul.ReactUiFilter__filters,
.ListController {
  padding: 1em 0;
}

.text-align--center {
  text-align: center;
}

.text-align--right {
  text-align: right;
}

.error {
  color: $brand-danger;
  background: rgba($brand-danger, 0.3);
  position: relative;

  &:before {
    content: '';
    margin-left: -3px;
    border-left: 3px solid #e64c42;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.cursor-pointer {
  cursor: pointer;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

//////////////////////////////
// BEFORE REFACTO
//////////////////////////////
.alk-relative {
  position: relative;
}

.alk-list {
  @include ul();
}

// Text styling
.alk-txt-center {
  text-align: center;
}

.alk-txt-right {
  text-align: right;
}

.alk-txt-danger {
  color: $brand-danger;
}

.alk-txt-orange {
  color: $brand-warning;
}

.alk-txt-success {
  color: $brand-success;
}

.alk-txt-gray-light {
  color: $gray-light;
}

.alk-txt-primary {
  color: $brand-primary;
}

.alk-txt-highlight {
  color: $brand-highlight;
}

::placeholder {
  color: $gray-light;
}

.alk-txt-gray {
  color: $gray-medium;
}

.alk-txt-muted {
  @extend .alk-txt-gray;
  font-style: italic;
}

.alk-margin {
  &-right {
    margin-right: $half-margin-base-horizontal;

    &--large {
      margin-right: $margin-base-horizontal;
    }

    &--small {
      margin-right: math.div($half-margin-base-horizontal, 2);
    }
  }

  &-bottom {
    margin-bottom: $half-margin-base-horizontal;

    &--large {
      margin-bottom: $margin-base-horizontal;
    }
    &--small {
      margin-bottom: math.div($half-margin-base-horizontal, 2);
    }
  }

  &-top {
    margin-top: $half-margin-base-horizontal;

    &--large {
      margin-top: $margin-base-horizontal;
    }
    &--small {
      margin-top: math.div($half-margin-base-horizontal, 2);
    }
  }

  &-left {
    margin-left: $half-margin-base-horizontal;

    &--large {
      margin-left: $margin-base-horizontal;
    }

    &-1 {
      margin-left: 1rem;
    }
  }
}

// Button-like thingies
.alk-clickable {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.alk-cursor-pointer {
  cursor: pointer;
  user-select: none;
}

.alk-clickable-icon {
  font-size: $font-size-h3;
  color: $gray-medium;

  &:hover {
    color: $brand-secondary;
  }
}

button[disabled] .alk-clickable-icon {
  color: $gray-lighter;
  &:hover {
    color: $gray-lighter;
  }
}

.alk-clickable-icon--secondary {
  color: $brand-secondary;

  &:hover {
    color: $brand-secondary-hover;
  }
}

.alk-clickable-icon--white {
  color: $brand-white;

  &:hover {
    color: $gray-lightest;
  }
}

.alk-btn-phantom {
  border: 0;
  background: transparent;
}

.alk-inline-block {
  display: inline-block;
}

// Flex
.alk-flex {
  display: flex;
  min-height: 0;
  min-width: 0;

  &-1 {
    flex: 1;
  }
  &-shrink-0 {
    flex-shrink: 0;
  }
  &-column {
    flex-direction: column;
  }
  &-row {
    flex-direction: row;
  }
  &-center {
    align-items: center;
  }
  &-start {
    align-items: start;
  }
  &-baseline {
    align-items: baseline;
  }
  &-justify-center {
    justify-content: center;
  }
  &-justify-right {
    justify-content: flex-end;
  }
  &-align-self-end {
    align-self: flex-end;
  }
  &-space-between {
    justify-content: space-between;
  }
  &-half-margin-horizontal {
    > * + * {
      margin-left: $half-margin-base-horizontal;
    }
  }
  &-margin-horizontal {
    > * + * {
      margin-left: $margin-base-horizontal;
    }
  }
  &-v-center {
    align-items: center;
    flex-direction: column;
  }

  &-grow {
    flex: 1;
  }
}
.alk-inline-flex {
  display: inline-flex;
  min-height: 0;
  min-width: 0;
}

// Align
.alk-text-align {
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
}

.alk-font-weight {
  &--bold {
    font-weight: 500;
  }
}

// Spinner
.loader {
  display: flex;
  align-items: center;
  > .Spinner {
    margin-right: $half-margin-base-horizontal;

    &.Spinner--small {
      margin-right: math.div($half-margin-base-horizontal, 2);
    }
  }
}

.alk-padding {
  &-base {
    padding: $padding-base-vertical $padding-base-horizontal;
  }

  &-large {
    padding: $padding-large-vertical $padding-large-horizontal;
  }

  &-v-base {
    padding: $padding-base-vertical 0;
  }

  &-v-large {
    padding: $padding-large-vertical 0;
  }

  &-top-large {
    padding-top: $padding-large-vertical;
  }

  &-top {
    padding-top: $padding-base-vertical;

    &-1 {
      padding-top: 1rem;
    }
    &--small {
      padding-top: $padding-small-vertical;
    }
  }

  &-bottom {
    padding-bottom: $padding-base-vertical;

    &-1 {
      padding-bottom: 1rem;
    }
    &-large {
      padding-bottom: $padding-large-vertical;
    }
    &--small {
      padding-top: $padding-small-vertical;
    }
  }
}

.card.alk-card-no-border {
  border: 0;
}

.scroller {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.Dropzone {
  width: 100%;
  padding: 100px 15px;
  border: 1px $brand-primary dashed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: $brand-secondary;
    border-color: $brand-secondary;
  }

  &.Dropzone--active {
    background-color: lighten($brand-primary, 40%);
    border-color: $brand-secondary;
  }

  .Label--red {
    color: $brand-danger;
  }
}
