@import '~@alkem/react-styles/src/vars';

.ConditionsDisplay {
  .pre {
    font-size: 90%;
    font-family: 'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New',
      monospace;
    display: block;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    margin-bottom: 0.3rem;
  }

  .ConditionDisplayItem {
    .ConditionDisplayItem__display {
      margin-bottom: 0.5rem;
      code {
        max-width: 40%;
        word-wrap: break-word;
        white-space: pre-wrap;
      }

      .string {
        color: $brand-inprogress;
      }
      .number {
        color: $brand-success;
      }
      .boolean {
        color: $brand-primary;
      }
      .null {
        color: $brand-warning;
      }
    }
  }
}

.ConditionsEditor {
  & > .InputWithLabel {
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .ConditionEditorItemsList {
    & > .InputWithLabel {
      align-items: flex-start;
    }
    .InputTextarea__input.form-control {
      border: 1px solid $input-border-color;
      min-height: 40px;
      padding: 8px;

      &.InputTextarea__input--invalid {
        border-color: $alk-red;
      }
      &:focus {
        border-color: $brand-primary;
      }
    }
    .ConditionEditorItems {
      margin-bottom: 1rem;
      border-left: 1px solid $input-border-color;
    }
    .ConditionEditorItem {
      margin-bottom: 0.25rem;
    }
    .ConditionEditorItem__key {
      flex-grow: unset;
      align-content: flex-end;
    }
    .ConditionEditorItem__value {
      flex-grow: 1;
      margin: 0 0.2rem;
    }
  }

  .InputTextarea,
  .InputField {
    flex-grow: 1;
    margin: 0 0.2rem;
  }

  .btn {
    &.no-padding {
      padding: 0;
    }
    &.small-padding {
      padding: 0.5rem;
    }
    &.bg-white {
      background-color: white;
      &:hover:not(.disabled) {
        color: $brand-primary;
      }
    }
    &.fake-btn {
      cursor: auto;
    }
    &.btn-link {
      &:hover.disabled {
        color: $brand-primary;
      }
    }
  }
}
