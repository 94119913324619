@import '~@alkem/react-styles/src/vars';

.GDSNExportMappingsView__body {
  overflow-x: hidden;
}

.GDSNExportMappingsView__content {
  margin: $margin-base $margin-base-horizontal;
  height: calc(99vh - #{$large-header-height} - #{$margin-base * 2});
}

.GDSNExportMappingsView__panel--left {
  width: 400px;
  .TwinPanel__content {
    overflow: auto;
    height: 100%;
  }
  .GDSNExportMappingsView__elementSelectorContainer {
    height: 100%;
  }
}

@media only screen and (max-width: 1300px) {
  .GDSNExportMappingsView__panel--left {
    width: 360px;
  }
}

.GDSNExportMappingsView__panel--right {
  flex-grow: 1;

  .TwinPanel__content {
    overflow: auto;
    height: 100%;
  }
}
