@import '~@alkem/react-styles/src/vars';

.EdaRmqReplayer__actions {
  display: flex;
  align-items: center;
  padding-bottom: 1em;
}

.EdaRmqReplayer__summary {
  padding-left: 1em;
}

.EdaRmqReplayer__list {
  padding: 0 1em 1em 1em;
}
