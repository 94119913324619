@import '~@alkem/react-styles/src/vars';

.RuleApplierCallTraceDetailSelection {
  margin-bottom: 30px;

  p {
    font-style: italic;
  }

  .LazyTooltip {
    width: 100%;
    margin-left: 20px;
  }
}

.RuleApplierCallTraceDetails__Header {
  margin-bottom: 10px;
}

.CallTrace {
  .ChoiceTree {
    display: contents;
    width: 80%;
    margin-right: 20px;
  }
}

.RuleApplierCallTraceDetails__choiceTreeItemMarker {
  font-weight: bolder;
  &--intermediate {
    color: $alk-orange-darker;
  }
  &--final {
    color: $alk-red;
  }
  &--success {
    color: $alk-green;
  }
}

.ApplyRuleMode__Symbol {
  width: 90%;
  margin-left: 20px;
  margin-bottom: 20px;
}
